<script setup>

import { formatUnixDate } from '@/utils'
defineProps({
  subscriptionDetails: {
    type: Object,
    required: true
  },
  customerDetails: {
    type: Object,
    required: true
  },
  loading: {
    type: Boolean,
    required: true
  }
})

const COLLECTION_METHOD_OPTIONS = [
  {
    label: 'Automatically',
    value: 'charge_automatically'
  },
  {
    label: 'Invoice',
    value: 'send_invoice'
  }
]

const COLLECTION_METHOD_OPTIONS_MAP = COLLECTION_METHOD_OPTIONS.reduce((acc, { label, value }) => {
  acc[value] = label
  return acc
}, {})

</script>

<template>
  <a-descriptions
    title="Subscription Info"
    layout="horizontal"
    size="small"
    bordered
  >
    <a-descriptions-item
      label="Created"
    >
      {{ formatUnixDate(subscriptionDetails?.created) }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Period Start"
    >
      {{ formatUnixDate(subscriptionDetails?.current_period_start) }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Period End"
    >
      {{ formatUnixDate(subscriptionDetails?.current_period_end) }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Device qty"
    >
      {{ subscriptionDetails?.billingItemsInfo?.billingDevicesQuantity }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Billing Cycle"
    >
      {{ formatUnixDate(subscriptionDetails?.billing_cycle_anchor) }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Next Invoice"
    >
      {{ formatUnixDate(subscriptionDetails?.next_pending_invoice_item_invoice) || 'None' }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Started At"
    >
      {{ formatUnixDate(subscriptionDetails?.start_date) || 'None' }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Ended At"
    >
      {{ formatUnixDate(subscriptionDetails?.ended_at) || 'None' }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Canceled At"
    >
      {{ formatUnixDate(subscriptionDetails?.canceled_at) || 'None' }}
    </a-descriptions-item>
    <template v-if="subscriptionDetails?.discount">
      <a-descriptions-item
        label="Discount Name"
      >
        {{ subscriptionDetails?.discount?.coupon?.name }}
      </a-descriptions-item>
      <a-descriptions-item
        label="Discount End"
      >
        {{ subscriptionDetails?.discount?.end || 'Forever' }}
      </a-descriptions-item>
      <a-descriptions-item
        label="Amount"
      >
        <span v-if="subscriptionDetails?.discount?.coupon?.currency">
          {{ subscriptionDetails?.discount?.coupon?.currency }}
          {{ subscriptionDetails?.discount?.coupon?.amount_off }}
        </span>
        <span v-else>
          {{ subscriptionDetails?.discount?.coupon?.percent_off }}%
        </span>
        <a-tooltip>
          <template #title>
            <JsonViewer
              :value="subscriptionDetails?.discount"
              copyable
              preview-mode
              sort
              theme="jv-light"
            />
          </template>
          <InfoCircleOutlined />
        </a-tooltip>
      </a-descriptions-item>
    </template>
    <template v-if="subscriptionDetails?.billingSettings">
      <a-descriptions-item
        label="Col. Method"
      >
        {{ COLLECTION_METHOD_OPTIONS_MAP[subscriptionDetails?.billingSettings?.collectionMethod] || 'None' }}
      </a-descriptions-item>
      <a-descriptions-item
        label="Days to dew"
      >
        {{ subscriptionDetails?.billingSettings?.daysUntilDue }}
      </a-descriptions-item>
      <a-descriptions-item
        label="Payment Method"
      >
        {{ subscriptionDetails?.billingSettings?.paymentMethodTypes || 'None' }}
      </a-descriptions-item>
    </template>
    <a-descriptions-item
      label="Balance"
    >
      ${{ customerDetails?.balance }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Status"
    >
      {{ subscriptionDetails?.status }}
    </a-descriptions-item>
    <a-descriptions-item
      label="Price Id"
    >
      {{ subscriptionDetails?.billingItemsInfo?.billingDevicePriceId }}
    </a-descriptions-item>
  </a-descriptions>
</template>

<style scoped>

</style>
