export const DAY_IN_MILLISECONDS = 60 * 60 * 24 * 1000

export const DAYS = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']

export const LANGUAGES_LIST = {
  RUSSIAN: 'Russian',
  ENGLISH_US: 'English',
  GERMAN: 'German',
  SPANISH: 'Spanish',
  FRENCH: 'French',
  ITALIAN: 'Italian',
  CHINESE: 'Chinese',
  PORTUGUESE: 'Portuguese',
  JAPANESE: 'Japanese'
}

export const COMPANY_SIZE_LIST = {
  JUST_ME: 'Just me',
  FROM_2_TO_5: '2-5',
  FROM_6_TO_20: '6-20',
  FROM_21_TO_50: '21-50',
  FROM_51_TO_100: '51-100',
  FROM_101_TO_500: '101-500',
  MORE_THAN_500: '500+'
}

export const DEFAULT_PAGINATION = {
  offset: 0,
  limit: 10
}

export const WORKSPACE_FEATURES_INFO = {
  externalApiEnabled: {
    name: 'External API',
    tooltip: 'Enable the use of the Kitcast API for this workspace.'
  },
  authorRoleEnabled: {
    name: 'Author Role',
    tooltip: 'Allow the use of the author role in this workspace (currently not utilized).'
  },
  ssoEnabled: {
    name: 'SSO',
    tooltip: 'Enable Single Sign-On (SSO) authentication for this workspace.'
  },
  whitelabelEnabled: {
    name: 'Whitelabel',
    tooltip: 'Allow customizing the dashboard for this workspace, including changing dashboard colors, uploading a custom logo for the dashboard, and setting a custom dashboard URL (the Kitcast logo is not removed).'
  },
  nestedGroupsEnabled: {
    name: 'Nested Groups (Sub-Groups)',
    tooltip: 'Allow creating and managing nested (sub-)groups within this workspace.'
  },
  playlistVersionsEnabled: {
    name: 'Playlist Versions (Time Machine)',
    tooltip: 'Enable time machine functionality for playlists, allowing version control and restoration in this workspace.'
  },
  multipleSocialAccountsEnabled: {
    name: 'Multiple Social Accounts',
    tooltip: 'Allow linking and managing multiple social media accounts within this workspace.'
  },
  externalMediaSyncEnabled: {
    name: 'External Media Sync',
    tooltip: 'Enable dynamic auto-updating of content from connected services like Google Drive or Dropbox for this workspace.'
  },
  smartGroupsEnabled: {
    name: 'Smart Groups',
    tooltip: 'Activate smart groups for dynamic screen management based on pre-set conditions and screens tags within this workspace.'
  },
  forcedHideDemoCoverEnabled: {
    name: 'Forced Hide Demo Cover (Outdated)',
    tooltip: 'Forcefully hide the demo cover notice on screens of this workspace.'
  },
  customWatermarkEnabled: {
    name: 'Custom Watermark',
    tooltip: 'Allow adding a custom watermark on content for this workspace.'
  },
  widgetWebsiteEnabled: {
    name: 'Website Widget',
    tooltip: 'On by default. If turned off, the website widget will be removed for this workspace.'
  },
  slideEditModalEnabled: {
    name: 'Custom Templates Editing Mode',
    tooltip: 'Enable a button in custom templates for editing the name and tags of selected templates in this workspace.'
  },
  forcedWatermarkToggleEnabled: {
    name: 'Forced Watermark Removal',
    tooltip: 'Allow the forced removal of the Kitcast watermark, even if the user is in Trial Mode.'
  },
  timelineSyncEnabled: {
    name: 'Timeline Sync',
    tooltip: 'Enable timeline synchronization between devices.'
  }
}
