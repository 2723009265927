<script setup>
import { ref, watch } from 'vue'
import { useQuery, useResult } from '@vue/apollo-composable'
import { GET_WORKSPACE_CUSTOMER, GET_WORKSPACE_SUBSCRIPTION } from '@/graphql/queries'
import SubscriptionInfo from '@/components/SubscriptionInfo.vue'
import SubscriptionActions from '@/components/SubscriptionActions.vue'

const props = defineProps({
  workspaceId: String,
  workspace: Object
})

const emit = defineEmits(['update'])

const { result: subscriptionResult, refetch: refetchSubscription, loading } = useQuery(GET_WORKSPACE_SUBSCRIPTION, { workspaceId: props.workspaceId }, { fetchPolicy: 'no-cache' })
const { result: customerResult } = useQuery(GET_WORKSPACE_CUSTOMER, { workspaceId: props.workspaceId }, { fetchPolicy: 'no-cache' })

const customerDetails = useResult(customerResult)
const subscriptionDetails = useResult(subscriptionResult)
const stripeCustomPriceId = ref(props.workspace?.billingInfo?.stripeCustomPriceId)


const handleSubscriptionUpdate = () => {
  refetchSubscription()
  emit('update')
}

watch(()=> props.workspace, (newVal) => {
  stripeCustomPriceId.value = newVal?.billingInfo?.stripeCustomPriceId
})

</script>

<template>
  <div style="height: 100%; padding-left: 16px; padding-bottom: 100px;">
    <a-spin
      :spinning="loading"
      style="width: 100%; min-height: 200px;"
    >
      <div
        v-if="!loading"
      >
        <div style="padding-bottom: 30px;">
          <SubscriptionInfo
            :loading="!!loading"
            :customer-details="customerDetails"
            :subscription-details="subscriptionDetails"
          />
          <a-divider />
          <SubscriptionActions
            :workspace="workspace"
            :subscription-details="subscriptionDetails"
            @update="handleSubscriptionUpdate"
          />
        </div>
        <div style="position: sticky; bottom: -24px; background-color: #fff; height: 50px; display: flex; align-items: center;">
          <a-space>
            <a
              :href="customerDetails?.stripeDashboardUrl"
              target="_blank"
            >Customer</a>
            <a-divider type="vertical" />
            <a-typography-link
              :href="subscriptionDetails?.stripeDashboardUrl"
              :disabled="!subscriptionDetails?.stripeDashboardUrl"
              target="_blank"
            >
              Subscription
            </a-typography-link>
          </a-space>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<style scoped>

</style>
