<script setup>
import { computed, ref, nextTick, onMounted } from 'vue'
import { useQuery } from '@vue/apollo-composable'
import { cloneDeep, debounce } from 'lodash'
import { formatDate } from '@/utils'
import { LIST_USERS } from '@/graphql/queries'
import { DEFAULT_PAGINATION, LANGUAGES_LIST } from '@/constants'
import { useRouter } from 'vue-router'
import UsersFilterPanel from '@/components/UsersFilterPanel.vue'
import { ClearOutlined, FilterOutlined } from '@ant-design/icons-vue'

const COLUMNS = [{
  title: 'Email',
  dataIndex: 'email',
  key: 'email',
  width: 200,
  minWidth: 100,
  maxWidth: 900,
  resizable: true
},
{
  title: 'Name',
  dataIndex: 'firstName',
  key: 'firstName',
  width: 200,
  minWidth: 200,
  maxWidth: 400,
  resizable: true
},
{
  title: 'Created At',
  dataIndex: 'createdAt',
  key: 'createdAt',
  width: 200,
  minWidth: 200,
  maxWidth: 200
},
{
  title: 'Company Name',
  dataIndex: 'companyName',
  key: 'companyName',
  width: 120,
  minWidth: 120,
  maxWidth: 120
},
{
  title: 'Language',
  dataIndex: 'language',
  key: 'language',
  width: 120,
  minWidth: 120,
  maxWidth: 120
}]

const settings = ref({ enabled: false, fetchPolicy: 'network-only' })
nextTick(() => {
  settings.value.enabled = true
})
const router = useRouter()
const searchValue = ref('')
const searchInputRef = ref(null)
const filtersPanelRef = ref(null)
const filtersPristine = ref(true)
const showFilterDrawer = ref(false)
const queryOptions = ref({ pagination: { ...DEFAULT_PAGINATION }, filters: { search: null } })
const { onResult, loading } = useQuery(LIST_USERS, queryOptions, settings)
const usersList = ref([])
const paginationResult = ref({})
const paginationConfig = computed(() => {
  return {
    total: paginationResult.value?.total,
    showLessItems: true,
    showSizeChanger: false
  }
})

onMounted(() => {
  searchInputRef.value?.focus()
})

const columns = ref(cloneDeep(COLUMNS))

onResult(({ data }) => {
  if (data?.listUsers?.data) {
    usersList.value = data.listUsers.data
    paginationResult.value = data.listUsers.pagination
  }
})

const handleResizeColumn = (w, col) => {
  col.width = w
}

const onChange = async (pagination) => {
  queryOptions.value.pagination.offset = (pagination.current - 1) * DEFAULT_PAGINATION.limit
}

const openFilterDrawer = () => {
  showFilterDrawer.value = true
}

const closeFilterDrawer = () => {
  showFilterDrawer.value = false
}

const onSearch = debounce(() => {
  if (searchValue.value.length > 2) {
    queryOptions.value.filters.search = searchValue.value.toLowerCase()
  } else if (searchValue.value === '') {
    queryOptions.value.filters.search = null
  }
}, 500)

const handleFiltersChange = debounce((filters) => {
  filters = { ...filters }
  for (const key in filters) {
    if (Array.isArray(filters[key]) && !filters[key].length) {
      filters[key] = null
    }
  }
  queryOptions.value.filters = { ...queryOptions.value.filters, ...filters }
}, 500)

const handleSearchEnter = () => {
  if (usersList.value?.length === 1) {
    router.push({ name: 'user', params: { userId: usersList.value[0].id } })
  }
}

const customRow = (record) => {
  return {
    onClick: () => {
      return router.push({ name: 'user', params: { userId: record.id } })
    }
  }
}

</script>

<template>
  <a-layout style="padding: 24px; text-align: left; min-height: 100%;">
    <a-layout-content>
      <a-drawer
        v-model:open="showFilterDrawer"
        title="Advanced filters"
        height="630px"
        placement="top"
        :closable="false"
      >
        <template #extra>
          <a-space>
            <a-button
              :disabled="filtersPristine"
              danger
              @click.stop="filtersPanelRef?.reset()"
            >
              Clear
            </a-button>
            <a-button @click="closeFilterDrawer">
              Close
            </a-button>
          </a-space>
        </template>
        <UsersFilterPanel
          ref="filtersPanelRef"
          v-model:pristine="filtersPristine"
          @update="handleFiltersChange"
        />
      </a-drawer>

      <a-space style="margin-bottom: 24px;">
        <a-input-search
          ref="searchInputRef"
          v-model:value="searchValue"
          placeholder="Input search text"
          style="width: 200px;"
          @change="onSearch"
          @keypress.enter="handleSearchEnter"
        />
        <a-button @click="openFilterDrawer">
          <template #icon>
            <FilterOutlined />
          </template>
          Advanced filters
        </a-button>
        <a-tooltip title="Clear filters">
          <a-button
            :disabled="filtersPristine"
            @click.stop="filtersPanelRef?.reset()"
          >
            <template #icon>
              <ClearOutlined />
            </template>
          </a-button>
        </a-tooltip>
      </a-space>
      <a-table
        :data-source="usersList"
        :columns="columns"
        :loading="loading"
        size="small"
        :pagination="paginationConfig"
        :custom-row="customRow"
        class="table-padded"
        @resize-column="handleResizeColumn"
        @change="onChange"
      >
        <template #bodyCell="{ column, text, record }">
          <template v-if="column.key === 'createdAt'">
            {{ formatDate(text) }}
          </template>
          <template v-else-if="column.key === 'companyName'">
            {{ text || 'Unset' }}
          </template>
          <template v-else-if="column.key === 'firstName'">
            {{ text }} {{ record.lastName }}
          </template>
          <template v-else-if="column.key === 'language'">
            {{ LANGUAGES_LIST[text] }}
          </template>
          <template v-else>
            {{ text }}
          </template>
        </template>
      </a-table>
    </a-layout-content>
  </a-layout>
</template>

<style lang="less">
.ant-table-row {
  cursor: pointer;
}

</style>
