<script setup>
import { defineProps, ref, watchEffect, defineEmits } from 'vue'
import { cloneDeep } from 'lodash'

const props = defineProps({
  features: {
    type: Object,
    required: true
  },
  featuresInfo: {
    type: Object,
    default: () => ({})
  },
  updating: {
    type: Boolean,
    default: () => false
  },
})

defineEmits(['toggle-feature'])

const featuresState = ref({})

watchEffect(() => {
  if (props.features) {
    const features = cloneDeep(props.features)
    delete features.__typename
    featuresState.value = features
  }
})
</script>

<template>
  <a-descriptions
    layout="horizontal"
    bordered
    style="text-align: left; overflow-x: auto"
  >
    <a-descriptions-item
      v-for="(value, feature) in featuresState"
      :key="feature"
      style="white-space: nowrap"
      :span="3"
    >
      <template #label>
        <template v-if="featuresInfo?.[feature]">
          <a-tooltip
            v-if="featuresInfo?.[feature]?.tooltip"
            :title="featuresInfo?.[feature]?.tooltip"
          >
            <span>{{ featuresInfo?.[feature]?.name || feature }}</span>
          </a-tooltip>
        </template>
        <span v-else>
          {{ featuresInfo?.[feature]?.name || feature }}
        </span>
      </template>
      <a-switch
        :disabled="updating"
        :checked="value"
        checked-children="Yes"
        un-checked-children="No"
        @click="(value)=> $emit('toggle-feature', {feature, value})"
      />
    </a-descriptions-item>
  </a-descriptions>
</template>

<style scoped>

</style>
