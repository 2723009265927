<script setup>
import { LIST_ADMIN_NOTES } from '@/graphql/queries'
import { useMutation, useQuery } from '@vue/apollo-composable'
import { PlusOutlined, EditOutlined, SaveOutlined, CloseOutlined, DeleteOutlined } from '@ant-design/icons-vue'
import { computed, nextTick, ref } from 'vue'
import { CREATE_ADMIN_NOTE, DELETE_ADMIN_NOTE_BY_ID, UPDATE_ADMIN_NOTE_BY_ID } from '@/graphql/mutations'
import { useStore } from 'vuex'
import { error } from '@/utils'

const props = defineProps({
  resourceId: {
    type: String,
    required: true
  },
  resource: {
    type: String,
    default: 'WORKSPACE'
  }
})

const store = useStore()

const userId = computed(()=>store.getters['auth/userId'])
const userName = computed(()=>store.getters['auth/userName'])

const { onResult, loading: fetchingNotes } = useQuery(LIST_ADMIN_NOTES, {
  filters: {
    resourceId: props.resourceId,
    resourceType: props.resource
  },
  pagination: {
    limit: 999,
    offset: 0
  }
}, { fetchPolicy: 'no-cache' })

const { mutate: createAdminNote, loading: creatingNote } = useMutation(CREATE_ADMIN_NOTE)
const { mutate: updateAdminNote, loading: updatingNote } = useMutation(UPDATE_ADMIN_NOTE_BY_ID)
const { mutate: deleteAdminNote, loading: deletingNote } = useMutation(DELETE_ADMIN_NOTE_BY_ID)

const loading = computed(() => fetchingNotes.value || creatingNote.value || updatingNote.value || deletingNote.value)

const hasNewNote = computed(() => notes.value.some((note) => !note.id))

const notes = ref([])

onResult((data) => {
  notes.value = data?.data?.listAdminNotes?.data || []
})


const onCreateNote = async () => {
  const note = {
    authorAdmin: {
      name: userName.value
    },
    text: 'New note',
    editing: true
  }
  notes.value.push(note)
  await nextTick()
  note.ref?.focus()
}

const onEditNote = async (note) => {
  note.editedText = note.text
  note.editing = true
  await nextTick()
  note.ref?.focus()
}

const onCancelEditNote = (note) => {
  if (!note.id) {
    notes.value = notes.value.filter((n) => n !== note)
    return
  }
  note.editedText = ''
  note.editing = false
}

const handleDeleteNote = (note) => {
  deleteAdminNote({
    id: note.id
  }).then(() => {
    notes.value = notes.value.filter((n) => n !== note)
  }).catch((e) => {
    error(e.message)
  })
}

const handleCreateNote = (note) => {
  if (!note.editedText) {
    return
  }
  if (note.id) {
    updateAdminNote({
      id: note.id,
      input: {
        text: note.editedText
      }
    }).then((data) => {
      notes.value = notes.value.map((n) => {
        if (n.id === note.id) {
          return data.data.updateAdminNoteById
        }
        return n
      })
    }).catch((e) => {
      error(e.message)
    })
    return
  }
  createAdminNote({
    input: {
      text: note.editedText,
      resourceId: props.resourceId,
      resourceType: props.resource
    }
  }).then((data) => {
    notes.value = notes.value.map((n) => {
      if (!n.id) {
        return data.data.createAdminNote
      }
      return n
    })
  }).catch((e) => {
    error(e.message)
  })
}

</script>

<template>
  <a-float-button
    v-if="!hasNewNote"
    shape="square"
    type="primary"
    :style="{
      right: '36px;',
    }"
    @click="onCreateNote"
  >
    <template #icon>
      <PlusOutlined />
    </template>
  </a-float-button>
  <a-list
    class="notes-list"
    :loading="loading"
    :data-source="notes"
  >
    <template #renderItem="{ item }">
      <a-list-item>
        <template #actions>
          <a-button
            v-if="item.editing"
            type="primary"
            :disabled="!item.editedText || item.editedText === item.text"
            @click="handleCreateNote(item)"
          >
            <template #icon>
              <SaveOutlined />
            </template>
          </a-button>
          <a-popconfirm
            v-if="!item.editing && item.id && userId === item.authorAdmin?.id"
            title="Are you sure?"
            @confirm="()=>handleDeleteNote(item)"
          >
            <a-button danger>
              <template #icon>
                <DeleteOutlined />
              </template>
            </a-button>
          </a-popconfirm>
          <a-button
            v-if="!item.editing && item.id && userId === item.authorAdmin?.id"
            @click="onEditNote(item)"
          >
            <template #icon>
              <EditOutlined />
            </template>
          </a-button>
          <a-button
            v-else-if="item.editing && item.id && userId === item.authorAdmin?.id"
            @click="onCancelEditNote(item)"
          >
            <template #icon>
              <CloseOutlined />
            </template>
          </a-button>
        </template>
        <a-list-item-meta>
          <template #description>
            <a-textarea
              v-if="item.editing"
              :ref="(el)=>item.ref = el"
              v-model:value="item.editedText"
              :rows="2"
              auto-size
              @keydown.esc="onCancelEditNote(item)"
            />
            <div
              v-else
              style="white-space: pre-wrap;"
            >
              {{ item.text }}
            </div>
          </template>
          <template
            v-if="item.authorAdmin?.name"
            #title
          >
            {{ item.authorAdmin?.name }}
          </template>
        </a-list-item-meta>
      </a-list-item>
    </template>
  </a-list>
</template>

<style scoped lang="less">
.notes-list {
  :deep(.ant-list-item-action) {
    align-self: flex-start;
    margin-top: 8px;
  }
}
</style>
