import gql from 'graphql-tag'

export const WORKSPACE_FEATURES = gql`
  fragment workspaceFeatures on WorkspaceFeatures {
    externalApiEnabled
    authorRoleEnabled
    ssoEnabled
    whitelabelEnabled
    nestedGroupsEnabled
    playlistVersionsEnabled
    multipleSocialAccountsEnabled
    externalMediaSyncEnabled
    smartGroupsEnabled
    forcedHideDemoCoverEnabled
    customWatermarkEnabled
    widgetWebsiteEnabled
    slideEditModalEnabled
    forcedWatermarkToggleEnabled
    timelineSyncEnabled
  }
`

export const WORKSPACE_MEDIA_BASE = gql`
  fragment workspaceMediaBase on WorkspaceMediaModel {
    id
    processingStatus
    processingData {
      profile
      error
    }
    generatedMedia {
      id
      tag
      url
    }
  }
`

export const PLAYLIST_BASE = gql`
  fragment playlistBase on PlaylistModel {
    id
    isPublished
    paused
    color
    name
  }
`

export const PLAYLIST_EXTENDED = gql`
  ${PLAYLIST_BASE}
  fragment playlistExtended on PlaylistModel {
    ...playlistBase
    createdAt
    updatedAt
    groupId
    authorIdentityId
  }
`

export const MEDIA_REFERENCE = gql`
  fragment mediaReference on MediaReference {
    sourceType
    ... on WorkspaceMediaReference {
      workspaceMediaId
    }
  }
`

export const SLIDE_BASE = gql`
  fragment slideBase on PlaylistLayoutZoneSlide {
    id
    duration
    paused
    widgetId
  }
`

export const WIDGET = gql`
  fragment widget on WidgetModel {
    id
    type
  }
`

export const WORKSPACE_SUBSCRIPTION = gql`
  fragment subscription on StripeSubscriptionInfo {
    id
    billing_cycle_anchor
    canceled_at
    created
    current_period_start
    current_period_end
    discount {
      coupon {
        amount_off
        currency
        duration
        duration_in_months
        name
        percent_off
      }
      end
      start
    }
    ended_at
    next_pending_invoice_item_invoice
    start_date
    status
    trial_end
    trial_start
    stripeDashboardUrl
    billingItemsInfo {
      billingDevicesQuantity
      billingDevicePriceId
    }
    billingSettings {
      collectionMethod
      daysUntilDue
      paymentMethodTypes
    }
  }
`

// NOTES

export const ADMIN_NOTE = gql`
  fragment adminNote on AdminNoteModel {
    authorAdmin {
      createdAt
      id
      is2faSet
      isPasswordSet
      name
      updatedAt
      username
    }
    authorAdminId
    createdAt
    id
    text
    updatedAt
  }
`
