import { gql } from 'graphql-tag'
import { ADMIN_NOTE, WORKSPACE_SUBSCRIPTION } from '@/graphql/fragments'

// AUTH

export const LOGIN_USER = gql`
  mutation loginAdmin ($username: String! $password: String! ){
    loginAdmin(username: $username password: $password ){
      sessionId
      accessToken
      admin {
        id
        username
        name
        is2faSet
      }
    }
  }
`

export const VERIFY_USER_EMAIL = gql`
  mutation verifyUserEmail($userId: String!) {
    verifyUserEmail(userId: $userId) 
  }
`

export const PREPARE_2FA_SETUP = gql`
  mutation prepare2faSetup {
    prepare2faSetup {
      otpUrl
    }
  }
`

export const FINALIZE_2FA_SETUP = gql`
  mutation finalize2faSetup($twoFactorCode: String!) {
    finalize2faSetup(twoFactorCode: $twoFactorCode)
  }
`

export const DISABLE_2FA = gql`
  mutation disable2fa {
    disable2fa
  }
`

export const PASS_TWO_FACTOR_CHECK = gql`
  mutation passTwoFactorCheck($preAuthToken: String! $twoFactorCode: String!) {
    passTwoFactorCheck(preAuthToken: $preAuthToken twoFactorCode: $twoFactorCode) {
      sessionId
      accessToken
      admin {
        id
        username
        name
        is2faSet
      }
    }
  }
`

export const LOGIN_AS_USER = gql`
  mutation loginOnBehalfOfUser ($userId: String!, $workspaceId: String){
    loginOnBehalfOfUser(userId: $userId, workspaceId:$workspaceId){
      accessToken
      sessionId
    }
  }
`

export const LOGOUT_ADMIN = gql`
  mutation logoutAdmin {
    logoutAdmin
  }
`

export const DELETE_SESSION_BY_ID = gql`
  mutation deleteSessionById ($sessionId: String!){
    deleteSessionById(sessionId: $sessionId)
  }
`

// USER

export const UPDATE_PROFILE = gql`
  mutation updateMyProfile ($input: UpdateUserProfileInput!){
    updateMyProfile(input: $input) {
      id
      email
      emailVerified
      firstName
      lastName
      phone
      companyName
      companySize
      language
      createdAt
      updatedAt
      stripePaymentMethodInfo {
        brand
        country
        exp_month
        exp_year
        last4
      }
    }
  }
`
export const UPDATE_USER_BY_ID = gql`
  mutation updateUserById ($id: String! $input: UpdateUserInput!){
    updateUserById(id: $id input: $input) {
      id
    }
  }
`

export const GENERATE_USER_PASSWORD_RESET_LINK = gql`
  mutation generateUserPasswordResetLink ($userId: String!){
    generateUserPasswordResetLink(userId: $userId) {
      url
    }
  }
`

export const CHANGE_USER_EMAIL = gql`
  mutation changeUserEmail ($userId: String! $input: ChangeUserEmailInput!){
    changeUserEmail(userId: $userId input: $input)
  }
`

export const APPLY_RIGHT_TO_BE_FORGOTTEN = gql`
  mutation applyRightToBeForgotten ($userId: String!){
    applyRightToBeForgotten(userId: $userId)
  }
`

// WORKSPACES

export const UPDATE_WORKSPACE_BY_ID = gql`
  mutation updateWorkspaceById ($id: String! $input: UpdateWorkspaceInput!) {
    updateWorkspaceById(id: $id input: $input) {
      id
      name
      billingInfo {
        freeDevicesQuantity
        billingDevicesQuantity
        enterprize
      }
      frozen 
    }
  }
`

// DEVICES

export const UPDATE_DEVICE_BY_ID = gql`
  mutation updateDeviceById ($id: String! $input: UpdateDeviceInput!) {
    updateDeviceById(id: $id input: $input) {
      id
      features {
        useWidgetWebsiteWebview
      }
    }
  }
`
// PLAYLISTS

export const PUBLISH_USER_PLAYLIST_VERSION = gql`
  mutation publishPlaylistVersion ($playlistId: String! $playlistVersion: Int!){
    publishPlaylistVersion(playlistId: $playlistId playlistVersion: $playlistVersion)
  }
`

export const SET_WORKSPACE_MEMBER_ROLE = gql`
  mutation setWorkspaceMemberRole ($workspaceId: String! $input: SetUserWorkspaceRoleInput!){
    setWorkspaceMemberRole(workspaceId: $workspaceId input: $input) {
      userId
      role
    }
  }
`
export const DELETE_WORKSPACE_MEMBER_ROLE = gql`
  mutation deleteWorkspaceMemberRole ($workspaceId: String! $userId: String!){
    deleteWorkspaceMemberRole(workspaceId: $workspaceId userId: $userId)
  }
`

// SUBSCRIPTIONS

export const UPDATE_WORKSPACE_SUBSCRIPTION = gql`
  ${WORKSPACE_SUBSCRIPTION}
  mutation updateWorkspaceSubscription ($workspaceId: String! $input: UpdateWorkspaceSubscriptionInput!){
    updateWorkspaceSubscription(workspaceId: $workspaceId input: $input) {
      ...subscription
    }
  }
`

export const CANCEL_WORKSPACE_SUBSCRIPTION = gql`
  mutation cancelWorkspaceSubscription($workspaceId: String!) {
    cancelWorkspaceSubscription(workspaceId: $workspaceId)
  }
`

export const SCHEDULE_UPDATE_WORKSPACE_SUBSCRIPTION = gql`
  mutation schedulePendingUpdateWorkspaceSubscription ($workspaceId: String! $input: ScheduleUpdateWorkspaceSubscriptionInput!){
    schedulePendingUpdateWorkspaceSubscription(workspaceId: $workspaceId input: $input)
  }
`

export const CANCEL_SCHEDULE_UPDATE_WORKSPACE_SUBSCRIPTION = gql`
  mutation cancelPendingUpdateWorkspaceSubscription ($workspaceId: String!){
    cancelPendingUpdateWorkspaceSubscription(workspaceId: $workspaceId)
  }
`

// NOTES

export const CREATE_ADMIN_NOTE = gql`
  ${ADMIN_NOTE}
  mutation createAdminNote ($input: CreateAdminNoteInput!){
    createAdminNote(input: $input) {
      ...adminNote
    }
  }
`
export const UPDATE_ADMIN_NOTE_BY_ID = gql`
  ${ADMIN_NOTE}
  mutation updateAdminNoteById ($id: String! $input: UpdateAdminNoteInput!){
    updateAdminNoteById(id:$id input: $input) {
      ...adminNote
    }
  }
`

export const DELETE_ADMIN_NOTE_BY_ID = gql`
  mutation deleteAdminNoteById ($id: String!){
    deleteAdminNoteById(id:$id)
  }
`
