<script setup>
import { ref } from 'vue'
import { useMutation, useQuery, useResult } from '@vue/apollo-composable'
import { cloneDeep } from 'lodash'
import { formatDate, success } from '@/utils'
import { LIST_WORKSPACE_DEVICES } from '@/graphql/queries'
import { SettingOutlined } from '@ant-design/icons-vue'
import FeaturesList from '@/components/FeaturesList.vue'
import { UPDATE_DEVICE_BY_ID } from '@/graphql/mutations'

const COLUMNS = [{
  title: 'Name',
  dataIndex: 'name',
  key: 'name',
  ellipsis: true,
  width: 200,
  minWidth: 50,
  maxWidth: 400,
  resizable: true,
  fixed: 'left'
}, {
  title: 'tvOS',
  key: 'hardwareInfo',
  dataIndex: 'hardwareInfo',
  width: 70,
  ellipsis: true
}, {
  title: 'App Version',
  dataIndex: 'appVersion',
  key: 'appVersion',
  width: 100,
  ellipsis: true
},{
  title: 'MDM',
  dataIndex: 'mdm',
  width: 60,
  key: 'mdm'
}, {
  title: 'Timezone',
  dataIndex: 'timezone',
  width: 100
}, {
  title: 'Orientation',
  dataIndex: 'orientation',
  key: 'orientation',
  width: 100,
  minWidth: 100
}, {
  title: 'Created At',
  dataIndex: 'createdAt',
  key: 'createdAt',
  align: 'createdAt',
  width: 150,
  minWidth: 150
}, {
  title: 'Updated At',
  dataIndex: 'updatedAt',
  key: 'updatedAt',
  align: 'updatedAt',
  width: 150,
  minWidth: 150
}, {
  title: 'Last activity At',
  dataIndex: 'lastActivityAt',
  key: 'lastActivityAt',
  align: 'lastActivityAt',
  width: 150,
  minWidth: 150
}, {
  title: 'Actions',
  dataIndex: 'actions',
  key: 'actions',
  width: 80,
  minWidth: 80
}]

const props = defineProps({
  workspaceId: {
    type: String
  }
})

const selectedDevice = ref(null)
const showDeviceModal = ref(false)
const showFeaturesDrawer = ref(false)
const queryOptions = ref({ workspaceId: props.workspaceId })
const { result, loading, refetch: refetchDevices } = useQuery(LIST_WORKSPACE_DEVICES, queryOptions, {fetchPolicy: 'no-cache'})
const { mutate: updateDevice, loading: updatingDevice } = useMutation(UPDATE_DEVICE_BY_ID )

const columns = ref(cloneDeep(COLUMNS))
const devicesList = useResult(result)


const handleResizeColumn = (w, col) => {
  col.width = w
}

const customRow = (record) => {
  return {
    onClick: () => {
      selectedDevice.value = record
      showDeviceModal.value = true
    }
  }
}

const openFeatures = (record) => {
  selectedDevice.value = record
  showFeaturesDrawer.value = true
}

const toggleFeature = ({ feature, value }) => {
  updateDevice({
    id: selectedDevice.value.id,
    input: {
      features: {
        [feature]: value
      }
    }
  }).then(() => {
    success('Feature updated')
    selectedDevice.value.features[feature] = value
    refetchDevices()
  }).catch(e => {
    console.error(e)
  })
}

</script>

<template>
  <a-modal
    v-model:open="showDeviceModal"
    :title="selectedDevice?.name"
    :footer="null"
    width="1000px"
  >
    <JsonViewer
      :value="selectedDevice"
      copyable
      expanded
      sort
      theme="jv-light"
    />
  </a-modal>
  <a-drawer
    v-model:open="showFeaturesDrawer"
    title="Features"
    placement="right"
  >
    <FeaturesList
      :features="selectedDevice?.features"
      @toggle-feature="toggleFeature"
    />
  </a-drawer>
  <a-table
    :data-source="devicesList"
    :columns="columns"
    :loading="loading"
    size="small"
    :custom-row="customRow"
    style="overflow-x: auto"
    :scroll="{ x: 800 }"
    class="table-padded"
    @resize-column="handleResizeColumn"
  >
    <template #bodyCell="{ column, text, record }">
      <template v-if="['createdAt','updatedAt','lastActivityAt'].includes(column.key)">
        {{ formatDate(text) }}
      </template>
      <template v-else-if="column.key === 'hardwareInfo'">
        {{ text?.tvos }}
      </template>
      <template v-else-if="column.key === 'mdm'">
        <a-badge
          :status="record.externalId ? 'success' : 'error'"
          :text="record.externalId ? 'Yes' : 'No'"
        />
      </template>
      <template v-else-if="column.key === 'actions'">
        <a-space>
          <a-tooltip title="Features">
            <a-button
              size="small"
              @click.stop="openFeatures(record)"
            >
              <SettingOutlined />
            </a-button>
          </a-tooltip>
        </a-space>
      </template>
      <template v-else>
        {{ text }}
      </template>
    </template>
  </a-table>
</template>

<style scoped>

</style>
